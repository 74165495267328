import { io } from 'socket.io-client';
import store from '@/store'
import { parseNode } from '@/helpers';
import EventBus from '@/event-bus';
// import { useToast } from "primevue/usetoast";
// const VUE_APP_SOCKET_ENDPOINT = 'http://localhost:3000';
// const VUE_APP_SOCKET_ENDPOINT_FALLBACK = 'http://192.168.88.11:3000';

let _system_log = [];

class SocketioService {
	socket;
	timer;
	alarmTimer;
	responeTimeout = 10000;
	alarmInterval = 10000;

	constructor() {

	}

	responeTimeoutCalc() {
		const timeout = store.getters.getAppSetup.responseTimeout;
		if (timeout && typeof(timeout) === "number" ) {
			return timeout*1000;
		}
		return this.responeTimeout;
	}
	alarmIntervalCalc() {
		const interval = store.getters.getAppSetup.alarmInterval;
		if (interval && typeof(interval) === "number" ) {
			console.log('interval:', interval);
			return interval*1000;
		}
		console.log('interval:', this.alarmInterval);
		return this.alarmInterval;
	}

	setupSocketConnection(host, port, protocol) {
		this.connectionTimer();
		if (process.env.VUE_APP_LOCALDEV === "true") {
			let path = '/'
			let url;
			port = 3001;
	
			// Socket.io base connection address same host as webapp but port 3000
			url = protocol + "//" + host + ":" + port + path;
			console.log("[SOCKETIO] LOCAL DEV URL: ", url);
	
			this.socket = io(url, {
				auth: {
					token: `Bearer ${localStorage.getItem('token')}`
				}
			});
		} else {
			const url = `${protocol}//${host}:${port}`;
			//url = protocol + "//" + host + path;
			//url = 'https:' + "//" + host;
			//const kaka = port + protocol + path;
			//console.log(kaka);
			
			console.log("SOCKETIO URL: ", url);
			const jwtToken = localStorage.getItem('token');
			console.log("JWTTOKEN: ", jwtToken);
			//url = '/enatsrv/';
			this.socket = io(url, {
				path: '/enatsrv/socket.io',
				cors: {
					origin: '*',
				},
				auth: {
					//token: `Bearer ${localStorage.getItem('token')}`
					token: `Bearer ${jwtToken}`
				},
				//transports: ["websocket"]
			});
		}

		
		// register Socket events
		this.removeListeners();
		// this.sockEventEssential();
		// this.sockEventConnection();
		// this.sockEventExtended();
		//this.sockEventValues();

		// request initial states
		// this.sockRequestEssential();
		this.init();
	}
	init() {
		// Handle disconnections
		this.socket.on('disconnect', () => {
			this.disconnect();
		});
	}
	getSocket() {
		return this.socket;
	}
	disconnect() {
		if (this.socket) {
			console.log(`[SOCKETIO] disconnect event for ${this.socket.id}`);
			this.stopAlarmFetchInterval();
			this.socket.removeAllListeners();
			this.socket.emit('byebye', this.socket.id);
			this.socket.disconnect();
			store.dispatch("setGlobalLoader", { loading: true, init: true, title: 'Verbindung unterbrochen' });
		}
	}
	removeListeners() {
		if (this.socket) {
			this.socket.removeAllListeners();
			console.log('Socket Event Listeners removed');
			// this.sockEventEssential();
		}
	}
	addEventListener(event) {
		if (this.socket) {
			this.socket.on(event.type, event.callback);
		}
	}
	removeEventListener(event) {
		if (this.socket) {
			this.socket.removeAllListeners(event.type);
		}
	}
	renewSocketToken(token) {
		if (this.socket && token) {
			const auth = { token: `Bearer ${token}` };
      this.socket.auth = auth;
			console.log(`[SOCKETIO] auth token renewed: ${token}`);
		}
	}

	
	connectionTimer() {
		if (this.responeTimeoutCalc() > 0) {
			clearTimeout(this.timer);
			this.timer = setTimeout(() => {
				store.dispatch("setSystemStatus", "offline");
				clearTimeout(this.timer);
				this.connectionTimer();
				this.disconnect();
				// store.dispatch("startSocketIo", null);
				store.dispatch("startSocketIo", null).then(() => { 
					console.log(`[INIT] all done (socketioService)`);
					store.dispatch("setGlobalLoader", { loading: false, init: true, title: '' });
					EventBus.emit('doneLoading');
					console.log(`[INIT] initial connection phase done!`);
				});
				
			},
			this.responeTimeoutCalc());
		}
	}
	sockEventEssential(callback) {
		// this.socket.on('dateTime', (data) => {
		// 	const _msg = "System online";
		// 	// console.log(data);
		// 	// _system_log = data;
		// 	clearTimeout(this.timer);
		// 	// this.connectionTimer();
		// 	callback(_msg, data.dateTime);
		// 	// store.dispatch("setSystemStatus", _msg);
		// 	// store.dispatch("setDateTime", data.dateTime);
		// });

		// console.log(localStorage.getItem('token'));
		
		this.socket.on('heartbeat1', (data) => {
			// const _msg = "System online";
			// console.log(data);
			callback(null, data);
			// _system_log = data;
			// clearTimeout(this.timer);
			// this.connectionTimer();
			// callback(_msg, data.dateTime);
			// store.dispatch("setSystemStatus", _msg);
			// store.dispatch("setDateTime", data.dateTime);
		});
	}
	registerConnectionEvent(callback) {
		// this.socket.on('connect', (data) => {
		// 	console.log(`[SOCKETIO] connection ${data}`);
		// 	callback(data);
		// });
		callback();
		
	}
	socketEventValue(callback) {
		this.socket.on('valueEvent2', (vars) => {
			// vars.forEach((element) => {
			// 		// store.dispatch('setValues', element.data);
			// });
			callback(vars);
		});
	}
	socketEventUuid(callback) {
		this.socket.on('uuidEvent', (vars) => {
			// vars.forEach((element) => {
			// 		// store.dispatch('setValues', element.data);
			// });
			callback(vars);
		});
	}
	async sendEvent(event, callback) {
		if (this.socket) {
			await new Promise(resolve => {
				this.socket.emit(event.type, event.data, (response) => {
					resolve(callback(response));
				});
			});
			
		}
	}

	socketEventConnection(callback) {
		this.socket.on('connect', () => {
			this.socket.emit('iAmReady', localStorage.getItem('token'));
			this.listenToHeartbeat();
			this.listenToOpcuaEvents();
			this.startAlarmFetchInterval();
			EventBus.emit('socketIoConnected');
			callback('>>> Socket connection established!')
			// console.log('>>> Socket connection established!');
		});

		this.socket.on('tokenRenewal', (data) => {
			store.dispatch('auth/loadNewToken', data);
		});

		this.socket.on('reconnect', () => {
			callback('>>> Socket connection re-established!')
			// console.log('Socket connection re-established!');
		});

		
	}

	socketEventConnectionError(callback) {
		this.socket.on('connect_error', (data) => {
			console.log(`[SOCKETIO] connect error: ${data}`);
			callback(data);
		});
	}
	// async sockEventEssential() {
	// 	await this.socket.on('dateTime', (data) => {
	// 		const _msg = "System online";
	// 		// console.log(data);
	// 		// _system_log = data;
	// 		clearTimeout(this.timer);
	// 		this.connectionTimer();
	// 		store.dispatch("setSystemStatus", _msg);
	// 		store.dispatch("setDateTime", data.dateTime);
	// 	});
	// }
	
	async sockEventExtended() {
		// store.dispatch("loadClientData");
		this.loadClientData();
		await this.socket.on('system_log', (data) => {
			// console.log(data);
			// _system_log = data;
			store.dispatch("setSystemLog", data);
		});

		await this.socket.on('connections', (data) => {
			store.dispatch("setActiveConnections", data);
		});

		// this.socket.on('clientData', (data) => {
		// 	// console.log("CLIENTDATA");
		// 	// console.log(data);
		// 	// _system_log = data;
		// 	store.dispatch("setClientData", data);
		// });

		this.socket.on('alarm_log', (data) => {
			// console.log(data);
			// _system_log = data;
			if (data !== null) {
				let alarmCnt = data.length;
				store.dispatch("setAlarmCount", alarmCnt);
				store.dispatch("setAlarmLog", data);
			}
		});
	}
	async sockEventValues() {
		this.socket.on('valueEvent', (vars) => {
			console.log(vars);
			// store.dispatch('setValues', vars);
		});
		// CURRENT valueEvent RECEIVER!!!
		await new Promise(resolve => {
			this.socket.on('valueEvent2', (vars) => {
				console.log(vars);
				vars.forEach((element) => {
						store.dispatch('setValues', element.data);
				});
				resolve(vars);
			});	
		});
		
		// UUID valueEvent RECEIVER!!!
		await new Promise(resolve => {
			this.socket.on('valueEvent99', (vars) => {
				console.log("UUID: ", vars);
				vars.forEach((element) => {
						store.dispatch('setValues2', element.data);
				});
				resolve(vars);
			});
		});
		
		this.socket.on('alarmEvent', (vars) => {
			console.log(vars);
			// vars.forEach((element) => {
			// 		store.dispatch('setValues', element.data);
			// });
		});
		this.socket.on('alarmList', (vars) => {
			console.log(vars);
			// vars.forEach((element) => {
			// 		store.dispatch('setValues', element.data);
			// });
		});
		this.socket.on('valueEvent33', (vars) => {
			// console.log(vars);
			vars.forEach((element) => {
					store.dispatch('setEventValue', element);
			});
		});
		this.socket.on('tmrValueEvent', (vars) => {
				vars.forEach((element) => {
						store.dispatch('setValues', element);
				});
		});
		console.log(store.getters.getValues);
	}
	async sockRequestEssential() {
		store.dispatch("setLoading", 1);
		if (this.socket) {
			await this.socket.emit('getClientData');
			await this.socket.emit('system', ('log'));
			await this.socket.emit('system', ('alarm_log'));
		}
		store.dispatch("setLoading", -1);
	}

	async loadClientData() {
		store.dispatch('setLoading', 1);
		await this.getServerData("config", "clientData", (err, response) => {
			console.log(response);
			store.dispatch('loadClientData', response);
			store.dispatch('setLoading', -1);
		});
	}
	// load current status io from backend
	async loadStatusIo() {
		await this.getServerData("status", "io", (err, response) => {
			console.log(response);
			store.dispatch('loadStatusIo', response);
		});
	}
	// load current config io from backend
	async loadConfigIo() {
		await this.getServerData("config", "io", (err, response) => {
			store.dispatch('loadConfigIo', response);
		});
	}
	// load IO-Tree list
	async loadIoTree() {
		store.dispatch('setLoading', 1);
		await this.getServerData("config", "ioTreeView", (err, response) => {
			store.dispatch('setIoTree', response);
			store.dispatch('setLoading', -1);
		});
	}
	// reload all relevant data
	reloadAll() {
		console.log("Fetching server data...");
		//this.loadStatusIo();
		this.loadConfigIo();
		this.loadIoTree();
	}

	// connectionTimer() {
	// 	const _msg = "System Offline!";
	// 	console.log('starting timer');
	// 	this.timer = setTimeout(store.dispatch("setSystemStatus", _msg), 3000);
	// }

	
	async getServerData(section, entry, callback) {
		if (this.socket) {
			store.dispatch("setLoading", 1);
			await new Promise(resolve => {
				this.socket.emit(section, entry, (err, response) => {
					// console.log(response);
					store.dispatch("setLoading", -1);
					resolve(callback(err, response));
				});
			});
		}

		
	}
	async setServerData(section, entry, data, callback) {
		if (this.socket) {
			store.dispatch("setLoading", 1);
			const userObj = store.getters['auth/getUser'];
			await this.socket.emit(section, entry, userObj, data, (err, response) => {
				store.dispatch("setLoading", -1);
				// console.log(err, response);
				callback(err, response);
			});
		}
	}


	async getClientData() {
		if (this.socket) {
			await this.socket.emit('getClientData', ('copyright'));
		}
	}
	getSystemLog() {
		if (this.socket) {
			// return new Promise((resolve) => resolve(_system_log));
			return _system_log;
		}
	}
	async getSystemConfigCopyright() {
		if (this.socket) {
			await this.socket.emit('getSystemConfig', ('copyright'));
		}
	}
	async getStatusIO(callback) {
		if (this.socket) {
			await this.socket.emit('status', ('io'));
		}
		this.socket.on('status_io', (data) => {
			// console.log(data);
			// _system_log = data;
			if (data !== null) {
				callback(data);
			}
		});
	}
	async getStatusIOTree(callback) {
		if (this.socket) {
			await this.socket.emit('status', ('ioTreeView'));
		}
		this.socket.on('status_io_treeview', (data) => {
			// console.log(data);
			// _system_log = data;
			if (data !== null) {
				callback(data);
			}
		});
	}
	async getSingleValue(_id, callback) {
		if (this.socket) {
			await this.socket.emit('getSingleValue', (_id));
		}
		this.socket.on('single_value', (data) => {
			// console.log(data);
			// _system_log = data;
			if (data !== null) {
				callback(data);
			}
		});
	}
	async removeValue(_id) {
		if (this.socket) {
			await this.socket.emit('remove_status_io', (_id));
		}
	}
	async getConfigIO(callback) {
		if (this.socket) {
			await this.socket.emit('config', ('io'));
		}
		this.socket.on('config_io', (data) => {
			// console.log(data);
			// _system_log = data;
			if (data !== null) {
				callback(data);
			}
		});
	}
	// async getViewMenu(callback) {
	// 	if (this.socket) {
	// 		await new Promise(resolve => {
	// 			this.socket.emit('view', 'menu', (response) => {
	// 				console.log(response);
	// 				resolve(callback(response));
	// 			});
	// 		});
	// 		// this.socket.emit('view', ('menu'), (response) => {
	// 		// 	console.log(response);
	// 		// 	callback(response);
	// 		// });
	// 	}
	// 	// this.socket.on('view_menu', (data) => {
	// 	// 	console.log(data);
	// 	// 	// _system_log = data;
	// 	// 	if (data !== null) {
	// 	// 		callback(data);
	// 	// 	}
	// 	// });
	// }

	// (2023) save heartbeat object
	listenToHeartbeat() {
		console.log(`[SOCKETIO] register heartbeat`);
		this.socket.on('heartbeat', (data) => {
			store.dispatch('socket/saveHeartbeat', data);
			this.connectionTimer();
			store.dispatch("setSystemStatus", "online");
		});
	}
	cancelHeartbeat() {
		this.socket.off('heartbeat');
	}
	// (2023) incoming value events from opcua server
	listenToOpcuaEvents() {
		this.socket.on('opcua.events.value', (data) => {
			// store.dispatch('socket/saveHeartbeat', data);
			// this.connectionTimer();
			// store.dispatch("setSystemStatus", "online");
			// console.log(data);
			if (Array.isArray(data) && data.length > 0) {
				store.dispatch('opcua/setNodes', data);
			}
		});

		this.socket.on('opcua.events.alarm', (data) => {
			// store.dispatch('socket/saveHeartbeat', data);
			// this.connectionTimer();
			// store.dispatch("setSystemStatus", "online");
			// console.log(data);
			// if (Array.isArray(data) && data.length > 0) {
			// 	// store.dispatch('opcua/setNodes', data);
				
			// }
			console.log('[SOCKETIO] ALARM EVENT INCOMING!');
			// console.log(data);
				store.dispatch('opcua/setAlarm', data);
		});
	}
	cancelOpcuaEvents() {
		this.socket.off('opcua.events.value');
	}
	// (2023) fetch alarm list with interval
	startAlarmFetchInterval() {
		if (this.alarmIntervalCalc() > 0) {
			console.log(`[APP-SETUP] starting alarm interval with ${this.alarmIntervalCalc()}`);
			this.alarmTimer = setInterval(() => {
				store.dispatch('opcua/loadAlarms', null);
			},
				this.alarmIntervalCalc());
		}
	}
	stopAlarmFetchInterval() {
		console.log(`[APP-SETUP] stopping alarm interval`);
		clearInterval(this.alarmTimer);
	}
	
	// (2023) get system installation mongodb
	async getSystemInstallation(callback) {
		try {
			const request = {
				type: "mongodb",
				cmd: "get",
				data: {
					collectionName: "systemConfig",
					query: { 'name': 'installation' }
				}
			}
			await this.getServerData('frontendDataRequest', request, (err, response) => {
				if (err) {
					throw err;
				} else {
					console.log(`[SOCKETIO] getSystemInstallation:`, response);
					callback(null, response[0]);
				}
			});
		} catch (err) {
			console.error(`[SOCKETIO] getSystemInstallation`, err);
			callback(err);
		}
	}
	// (2023) set system installation mongodb
	async setSystemInstallation(data, callback) {
		try {
			let query = {};
			if (data._id) {
				query._id = data._id;
			} else {
				query.name = 'installation';
			}
			
			const request = {
				type: "mongodb",
				cmd: "set",
				data: {
					collectionName: "systemConfig",
					query: query,
					payload: { 
						client: { ...data.client },
						location: { ...data.location },
						system: { ...data.system }
					}
				}
			}
			if (this.socket && data) {
				console.log('request: ', request);
				await this.socket.emit('frontendDataRequest', request, (err, response) => {
					if (err) {
						throw err;
					} else {
						console.log(`[SOCKETIO] setSystemInstallation:`, response);
						callback(null, response);
					}
				});
			}
		} catch (err) {
			console.error(`[SOCKETIO] setSystemInstallation`, err);
			callback(err);
		}
	}
	// (2023) get system copyright mongodb
	async getSystemCopyright(callback) {
		try {
			const request = {
				type: "mongodb",
				cmd: "get",
				data: {
					collectionName: "systemConfig",
					query: { 'name': 'copyright' }
				}
			}
			await this.getServerData('frontendDataRequest', request, (err, response) => {
				if (err) {
					throw err;
				} else {
					console.log(`[SOCKETIO] getSystemCopyright:`, response);
					callback(null, response[0]);
				}
			});
		} catch (err) {
			console.error(`[SOCKETIO] getSystemCopyright`, err);
			callback(err);
		}
	}
	// (2023) set system copyright mongodb
	async setSystemCopyright(data, callback) {
		try {
			let query = {};
			if (data._id) {
				query._id = data._id
			} else {
				query.name = 'copyright';
			}
			
			const request = {
				type: "mongodb",
				cmd: "set",
				data: {
					collectionName: "systemConfig",
					query: query,
					payload: data.payload
				}
			}
			if (this.socket && data) {
				console.log('request: ', request);
				await this.socket.emit('frontendDataRequest', request, (err, response) => {
					if (err) {
						throw err;
					} else {
						console.log(`[SOCKETIO] setSystemCopyright:`, response);
						callback(null, response);
					}
				});
			}
		} catch (err) {
			console.error(`[SOCKETIO] setSystemApp`, err);
			callback(err);
		}
	}
	// (2023) get system network mongodb
	async getSystemNetwork(callback) {
		try {
			const request = {
				type: "mongodb",
				cmd: "get",
				data: {
					collectionName: "systemConfig",
					query: { 'name': 'network' }
				}
			}
			await this.getServerData('frontendDataRequest', request, (err, response) => {
				if (err) {
					throw err;
				} else {
					console.log(`[SOCKETIO] getSystemNetwork:`, response);
					callback(null, response[0]);
				}
			});
		} catch (err) {
			console.error(`[SOCKETIO] getSystemNetwork`, err);
			callback(err);
		}
	}
	// (2023) set system network mongodb
	async setSystemNetwork(data, callback) {
		try {
			let query = {};
			if (data._id) {
				query._id = data._id;
			} else {
				query.name = 'network';
			}

			let payload = {};
			if (data.addresses) payload.addresses = { ...data.addresses };
			if (data.notifications) payload.notifications = { ...data.notifications };
			if (data.influxdb) payload.influxdb = { ...data.influxdb };
			if (data.noip) payload.noip = { ...data.noip };			
			
			const request = {
				type: "mongodb",
				cmd: "set",
				data: {
					collectionName: "systemConfig",
					query: query,
					payload: { ... payload }
				}
			}
			if (this.socket && data) {
				console.log('request: ', request);
				await this.socket.emit('frontendDataRequest', request, (err, response) => {
					if (err) {
						throw err;
					} else {
						console.log(`[SOCKETIO] setSystemNetwork:`, response);
						callback(null, response);
					}
				});
			}
		} catch (err) {
			console.error(`[SOCKETIO] setSystemInstallation`, err);
			callback(err);
		}
	}
	// (2023) get system app mongodb
	async getSystemApp(callback) {
		try {
			const request = {
				type: "mongodb",
				cmd: "get",
				data: {
					collectionName: "systemConfig",
					query: { 'name': 'app' }
				}
			}
			await this.getServerData('frontendDataRequest', request, (err, response) => {
				if (err) {
					throw err;
				} else {
					console.log(`[SOCKETIO] getSystemApp:`, response);
					callback(null, response[0]);
				}
			});
		} catch (err) {
			console.error(`[SOCKETIO] getSystemApp`, err);
			callback(err);
		}
	}
	// (2023) set system app mongodb
	async setSystemApp(data, callback) {
		try {
			let query = {};
			if (data._id) {
				query._id = data._id
			} else {
				query.name = 'app';
			}
			
			const request = {
				type: "mongodb",
				cmd: "set",
				data: {
					collectionName: "systemConfig",
					query: query,
					payload: {
						standbyTimeout: data.standbyTimeout,
						responseTimeout: data.responseTimeout,
						alarmInterval: data.alarmInterval,
						discordLink: data.discordLink,
						youtubeLink: data.youtubeLink,
						dropboxLink: data.dropboxLink,
						useGeoSphere: data.useGeoSphere
					}
				}
			}
			if (this.socket && data) {
				console.log('request: ', request);
				await this.socket.emit('frontendDataRequest', request, (err, response) => {
					if (err) {
						throw err;
					} else {
						console.log(`[SOCKETIO] setSystemApp:`, response);
						callback(null, response);
					}
				});
			}
		} catch (err) {
			console.error(`[SOCKETIO] setSystemApp`, err);
			callback(err);
		}
	}
	// (2023) get menu mongodb
	async getViewMenu(callback) {
		try {
			const request = {
				type: "mongodb",
				cmd: "get",
				data: {
					collectionName: "viewMenu",
					query: {}
				}
			}
			await this.getServerData('frontendDataRequest', request, (err, response) => {
				if (err) {
					throw err;
				} else {
					console.log(`[SOCKETIO] getViewMenu:`, response);
					callback(null, response.sort((a, b) => a.index - b.index));
				}
			});
		} catch (err) {
			console.error(`[SOCKETIO] getViewMenu`, err);
			callback(err);
		}
	}
	// (2023) set view menu mongodb
	async setViewMenu(data, callback) {
		try {
			let query = {};
			let items = null;
			if (data._id) {
				query._id = data._id
			}
			if (data.items) {
				items = [ ...data.items ];
			}
			
			const request = {
				type: "mongodb",
				cmd: "set",
				data: {
					collectionName: "viewMenu",
					query: query,
					payload: {
						label: data.label,
						category: data.category,
						icon: data.icon,
						desc: data.desc,
						to: data.to,
						root: data.root,
						parent: data.parent,
						index: data.index,
						items: items
					}
				}
			}
			if (this.socket && data) {
				console.log('request: ', request);
				await this.socket.emit('frontendDataRequest', request, (err, response) => {
					if (err) {
						throw err;
					} else {
						console.log(`[SOCKETIO] setViewMenu:`, response);
						callback(null, response);
					}
				});
			}
		} catch (err) {
			console.error(`[SOCKETIO] setViewMenu`, err);
			callback(err);
		}
	}
	// (2023) set view menu (array) mongodb
	async setViewMenuArray(data, callback) {
		try {
			let query = {};
			
			const request = {
				type: "mongodb",
				cmd: "set",
				data: {
					collectionName: "viewMenu",
					query: query,
					payload: [ ...data ]
				}
			}
			if (this.socket && data) {
				console.log('request: ', request);
				await this.socket.emit('frontendDataRequest', request, (err, response) => {
					if (err) {
						throw err;
					} else {
						console.log(`[SOCKETIO] setViewMenuArray:`, response);
						callback(null, response);
					}
				});
			}
		} catch (err) {
			console.error(`[SOCKETIO] setViewMenuArray`, err);
			callback(err);
		}
	}
	// (2023) del view menu mongodb
	async delViewMenu(data, callback) {
		try {
			let query = {};
			if (data._id) {
				query._id = data._id
			}
			
			const request = {
				type: "mongodb",
				cmd: "del",
				data: {
					collectionName: "viewMenu",
					query: query
				}
			}
			if (this.socket && query._id) {
				console.log('request: ', request);
				await this.socket.emit('frontendDataRequest', request, (err, response) => {
					if (err) {
						throw err;
					} else {
						console.log(`[SOCKETIO] delViewMenu:`, response);
						callback(null, response);
					}
				});
			}
		} catch (err) {
			console.error(`[SOCKETIO] delViewMenu`, err);
			callback(err);
		}
	}
	// (2023) get view page mongodb
	async getViewPage(callback) {
		try {
			const request = {
				type: "mongodb",
				cmd: "get",
				data: {
					collectionName: "viewPage",
					query: {}
				}
			}
			await this.getServerData('frontendDataRequest', request, (err, response) => {
				if (err) {
					throw err;
				} else {
					console.log(`[SOCKETIO] getViewPage:`, response);
					callback(null, response.sort((a, b) => a.index - b.index));
				}
			});
		} catch (err) {
			console.error(`[SOCKETIO] getViewPage`, err);
			callback(err);
		}
	}
	// (2023) set view page mongodb
	async setViewPage(data, callback) {
		try {
			let query = {};
			let components = null;
			if (data._id) {
				query._id = data._id
			}
			if (data.components) {
				components = [ ...data.components ];
			}
			
			const request = {
				type: "mongodb",
				cmd: "set",
				data: {
					collectionName: "viewPage",
					query: query,
					payload: {
						label: data.label,
						content: data.content,
						key: data.key,
						type: data.type,
						name: data.name,
						style: data.style,
						components: components
					}
				}
			}
			if (this.socket && data) {
				console.log('request: ', request);
				await this.socket.emit('frontendDataRequest', request, (err, response) => {
					if (err) {
						throw err;
					} else {
						console.log(`[SOCKETIO] setViewPage (${data.label}):`, response);
						callback(null, response, data);
					}
				});
			}
		} catch (err) {
			console.error(`[SOCKETIO] setViewPage`, err);
			callback(err);
		}
	}
	// (2023) del view page mongodb
	async delViewPage(data, callback) {
		try {
			let query = {};
			if (data._id) {
				query._id = data._id
			}
			
			const request = {
				type: "mongodb",
				cmd: "del",
				data: {
					collectionName: "viewPage",
					query: query
				}
			}
			if (this.socket && query._id) {
				console.log('request: ', request);
				await this.socket.emit('frontendDataRequest', request, (err, response) => {
					if (err) {
						throw err;
					} else {
						console.log(`[SOCKETIO] delViewPage:`, response);
						callback(null, response);
					}
				});
			}
		} catch (err) {
			console.error(`[SOCKETIO] delViewPage`, err);
			callback(err);
		}
	}
	// (2023) get view page entry mongodb
	async getViewPageEntry(id, callback) {
		try {
			const request = {
				type: "mongodb",
				cmd: "getOne",
				data: {
					collectionName: "viewPage",
					query: { name: id}
				}
			}
			await this.getServerData('frontendDataRequest', request, (err, response) => {
				if (err) {
					throw err;
				} else {
					console.log(`[SOCKETIO] getViewPageEntry:`, response);
					callback(null, response);
				}
			});
		} catch (err) {
			console.error(`[SOCKETIO] getViewPageEntry`, err);
			callback(err);
		}
	}
	// (2023) get module types mongodb
	async getUnitTypes(callback) {
		try {
			const request = {
				type: "mongodb",
				cmd: "get",
				data: {
					collectionName: "moduleTypes",
					query: { name: 'unitTypes'}
				}
			}
			await this.getServerData('frontendDataRequest', request, (err, response) => {
				if (err) {
					throw err;
				} else {
					console.log(`[SOCKETIO] getUnitTypes:`, response);
					callback(null, response[0]);
				}
			});
		} catch (err) {
			console.error(`[SOCKETIO] getUnitTypes`, err);
			callback(err);
		}
	}
	// (2023) set unit types mongodb
	async setUnitTypes(data, callback) {
		try {
			let query = {};
			if (data._id) {
				query._id = data._id;
			} else {
				query.name = 'unitTypes';
			}
			const request = {
				type: "mongodb",
				cmd: "set",
				data: {
					collectionName: "moduleTypes",
					query: query,
					payload: { 
						children: [ ...data.data ]
					}
				}
			}

			if (this.socket && data) {
				console.log('request: ', request);
				await this.socket.emit('frontendDataRequest', request, (err, response) => {
					if (err) {
						throw err;
					} else {
						console.log(`[SOCKETIO] setUnitTypes:`, response);
						callback(null, response);
					}
				});
			}
		} catch (err) {
			console.error(`[SOCKETIO] setUnitTypes`, err);
			callback(err);
		}
	}
	// (2023) get module types mongodb
	async getCategoryTypes(callback) {
		try {
			const request = {
				type: "mongodb",
				cmd: "get",
				data: {
					collectionName: "moduleTypes",
					query: { name: 'categoryTypes'}
				}
			}
			await this.getServerData('frontendDataRequest', request, (err, response) => {
				if (err) {
					throw err;
				} else {
					console.log(`[SOCKETIO] getCategoryTypes:`, response);
					callback(null, response[0]);
				}
			});
		} catch (err) {
			console.error(`[SOCKETIO] getCategoryTypes`, err);
			callback(err);
		}
	}
	// (2023) get module types mongodb
	async setCategoryTypes(data, callback) {
		try {
			let query = {};
			if (data._id) {
				query._id = data._id;
			} else {
				query.name = 'categoryTypes';
			}
			const request = {
				type: "mongodb",
				cmd: "set",
				data: {
					collectionName: "moduleTypes",
					query: query,
					payload: { 
						children: [ ...data.data ]
					}
				}
			}

			if (this.socket && data) {
				console.log('request: ', request);
				await this.socket.emit('frontendDataRequest', request, (err, response) => {
					if (err) {
						throw err;
					} else {
						console.log(`[SOCKETIO] setCategoryTypes:`, response);
						callback(null, response);
					}
				});
			}
		} catch (err) {
			console.error(`[SOCKETIO] setCategoryTypes`, err);
			callback(err);
		}
	}
	// (2023) get module types mongodb
	async getDepartmentTypes(callback) {
		try {
			const request = {
				type: "mongodb",
				cmd: "get",
				data: {
					collectionName: "moduleTypes",
					query: { name: 'departmentTypes'}
				}
			}
			await this.getServerData('frontendDataRequest', request, (err, response) => {
				if (err) {
					throw err;
				} else {
					console.log(`[SOCKETIO] getDepartmentTypes:`, response);
					callback(null, response[0]);
				}
			});
		} catch (err) {
			console.error(`[SOCKETIO] getDepartmentTypes`, err);
			callback(err);
		}
	}
	// (2023) set department types mongodb
	async setDepartmentTypes(data, callback) {
		try {
			let query = {};
			if (data._id) {
				query._id = data._id;
			} else {
				query.name = 'departmentTypes';
			}
			const request = {
				type: "mongodb",
				cmd: "set",
				data: {
					collectionName: "moduleTypes",
					query: query,
					payload: { 
						children: [ ...data.data ]
					}
				}
			}

			if (this.socket && data) {
				console.log('request: ', request);
				await this.socket.emit('frontendDataRequest', request, (err, response) => {
					if (err) {
						throw err;
					} else {
						console.log(`[SOCKETIO] setDepartmentTypes:`, response);
						callback(null, response);
					}
				});
			}
		} catch (err) {
			console.error(`[SOCKETIO] setDepartmentTypes`, err);
			callback(err);
		}
	}
	// (2023) get module types mongodb
	async getIconTypes(callback) {
		try {
			const request = {
				type: "mongodb",
				cmd: "get",
				data: {
					collectionName: "moduleTypes",
					query: { name: 'iconTypes'}
				}
			}
			await this.getServerData('frontendDataRequest', request, (err, response) => {
				if (err) {
					throw err;
				} else {
					console.log(`[SOCKETIO] getIconTypes:`, response);
					callback(null, response[0]);
				}
			});
		} catch (err) {
			console.error(`[SOCKETIO] getIconTypes`, err);
			callback(err);
		}
	}
	// (2023) set icon types mongodb
	async setIconTypes(data, callback) {
		try {
			let query = {};
			if (data._id) {
				query._id = data._id;
			} else {
				query.name = 'iconTypes';
			}
			const request = {
				type: "mongodb",
				cmd: "set",
				data: {
					collectionName: "moduleTypes",
					query: query,
					payload: { 
						children: [ ...data.data ]
					}
				}
			}

			if (this.socket && data) {
				console.log('request: ', request);
				await this.socket.emit('frontendDataRequest', request, (err, response) => {
					if (err) {
						throw err;
					} else {
						console.log(`[SOCKETIO] setDepartmentTypes:`, response);
						callback(null, response);
					}
				});
			}
		} catch (err) {
			console.error(`[SOCKETIO] setDepartmentTypes`, err);
			callback(err);
		}
	}
	// (2023) get opc servers mongodb
	async getOpcuaServers(callback) {
		try {
			const request = {
				type: "mongodb",
				cmd: "get",
				data: {
					collectionName: "configOpc",
					query: { name: 'server'}
				}
			}
			await this.getServerData('frontendDataRequest', request, (err, response) => {
				if (err) {
					throw err;
				} else {
					console.log(`[SOCKETIO] getOpcuaServers:`, response);
					callback(null, response[0]);
				}
			});
		} catch (err) {
			console.error(`[SOCKETIO] getOpcuaServers`, err);
			callback(err);
		}
	}
	// (2023) set opc servers mongodb
	async setOpcuaServers(data, callback) {
		try {
			let query = {};
			if (data._id) {
				query._id = data._id;
			} else {
				query.name = 'opcServerList';
			}
			const request = {
				type: "mongodb",
				cmd: "set",
				data: {
					collectionName: "configOpc",
					query: query,
					payload: { 
						children: [ ...data.data ]
					}
				}
			}

			if (this.socket && data) {
				console.log('request: ', request);
				await this.socket.emit('frontendDataRequest', request, (err, response) => {
					if (err) {
						throw err;
					} else {
						console.log(`[SOCKETIO] setOpcuaServers:`, response);
						callback(null, response);
					}
				});
			}
		} catch (err) {
			console.error(`[SOCKETIO] setDepartmentTypes`, err);
			callback(err);
		}
	}
	// (2023) get change log mongodb
	async getChangeLog(callback) {
		try {
			const request = {
				type: "mongodb",
				cmd: "get",
				data: {
					collectionName: "logChange",
					query: {}
				}
			}
			await this.getServerData('frontendDataRequest', request, (err, response) => {
				if (err) {
					throw err;
				} else {
					console.log(`[SOCKETIO] getChangeLog:`, response);
					callback(null, response);
				}
			});
		} catch (err) {
			console.error(`[SOCKETIO] getChangeLog`, err);
			callback(err);
		}
	}
	// (2023) delete change log mongodb
	async delChangeLog(data, callback) {
		try {
			let query = {};
			if (data._id) {
				query._id = data._id
			}

			const request = {
				type: "mongodb",
				cmd: "del",
				data: {
					collectionName: "logChange",
					query: query
				}
			}
			if (this.socket && query._id) {
				console.log('request: ', request);
				await this.socket.emit('frontendDataRequest', request, (err, response) => {
					if (err) {
						throw err;
					} else {
						console.log(`[SOCKETIO] delChangeLog:`, response);
						callback(null, response);
					}
				});
			}
		} catch (err) {
			console.error(`[SOCKETIO] delChangeLog`, err);
			callback(err);
		}
	}
	// (2023) get alarm log mongodb
	async getAlarmLog(callback) {
		try {
			const request = {
				type: "mongodb",
				cmd: "get",
				data: {
					collectionName: "logAlarm",
					query: {}
				}
			}
			await this.getServerData('frontendDataRequest', request, (err, response) => {
				if (err) {
					throw err;
				} else {
					console.log(`[SOCKETIO] getAlarmLog:`, response);
					callback(null, response);
				}
			});
		} catch (err) {
			console.error(`[SOCKETIO] getAlarmLog`, err);
			callback(err);
		}
	}
	// (2023) delete alarm log mongodb
	async delAlarmLog(data, callback) {
		try {
			let query = {};
			if (data._id) {
				query._id = data._id
			}

			const request = {
				type: "mongodb",
				cmd: "del",
				data: {
					collectionName: "logAlarm",
					query: query
				}
			}
			if (this.socket && query._id) {
				console.log('request: ', request);
				await this.socket.emit('frontendDataRequest', request, (err, response) => {
					if (err) {
						throw err;
					} else {
						console.log(`[SOCKETIO] delAlarmLog:`, response);
						callback(null, response);
					}
				});
			}
		} catch (err) {
			console.error(`[SOCKETIO] delAlarmLog`, err);
			callback(err);
		}
	}
	// (2023) get one user mongodb
	async getUser(username, callback) {
		try {
			const request = {
				type: "mongodb",
				cmd: "getOne",
				data: {
					collectionName: "userList",
					query: { username: username }
				}
			}
			await this.getServerData('frontendDataRequest', request, (err, response) => {
				if (err) {
					throw err;
				} else {
					console.log(`[SOCKETIO] getUser:`, response);
					callback(null, response);
				}
			});
		} catch (err) {
			console.error(`[SOCKETIO] getUser`, err);
			callback(err);
		}
	}
	// (2023) get user list mongodb
	async getUserList(callback) {
		try {
			const request = {
				type: "mongodb",
				cmd: "get",
				data: {
					collectionName: "userList",
					query: {}
				}
			}
			await this.getServerData('frontendDataRequest', request, (err, response) => {
				if (err) {
					throw err;
				} else {
					console.log(`[SOCKETIO] getUserList:`, response);
					callback(null, response);
				}
			});
		} catch (err) {
			console.error(`[SOCKETIO] getUserList`, err);
			callback(err);
		}
	}
	// (2023) set user list mongodb
	async setUserList(data, callback) {
		try {
			let query = {};
			if (data._id) {
				query._id = data._id
			}
			
			const request = {
				type: "mongodb",
				cmd: "set",
				data: {
					collectionName: "userList",
					query: query,
					payload: {
						active: data.active,
						fullname: data.fullname,
						role: data.role,
						username: data.username,
						notifications: data.notifications,
						config: data.config
					}
				}
			}
			if (data.newPassword) {
				request.data.payload.password = data.newPassword;
			}
			if (this.socket && data) {
				console.log('request: ', request);
				await this.socket.emit('frontendDataRequest', request, (err, response) => {
					if (err) {
						throw err;
					} else {
						console.log(`[SOCKETIO] getUserList:`, response);
						callback(null, response);
					}
				});
			}
		} catch (err) {
			console.error(`[SOCKETIO] setUserList`, err);
			callback(err);
		}
	}
	// (2023) del user mongodb
	async delUser(data, callback) {
		try {
			let query = {};
			if (data._id) {
				query._id = data._id
			}
			
			const request = {
				type: "mongodb",
				cmd: "del",
				data: {
					collectionName: "userList",
					query: query
				}
			}
			if (this.socket && query._id) {
				console.log('request: ', request);
				await this.socket.emit('frontendDataRequest', request, (err, response) => {
					if (err) {
						throw err;
					} else {
						console.log(`[SOCKETIO] delUser:`, response);
						callback(null, response);
					}
				});
			}
		} catch (err) {
			console.error(`[SOCKETIO] delUser`, err);
			callback(err);
		}
	}
	// (2023) get user group mongodb
	async getUserGroupList(callback) {
		try {
			const request = {
				"type": "mongodb",
				"cmd": "get",
				"data": {
					"collectionName": "userGroup",
					"query": {}
				}
			}
			this.getServerData('frontendDataRequest', request, (err, response) => {
				if (err) {
					throw err;
				} else {
					console.log(`[SOCKETIO] getUserGroup:`, response);
					callback(null, response);
				}
			});
		} catch (err) {
			console.error(`[SOCKETIO] getUserList`, err);
			callback(err);
		}
	}
	// (2023) del user group mongodb
	async delUserGroup(data, callback) {
		try {
			let query = {};
			if (data._id) {
				query._id = data._id
			}
			
			const request = {
				type: "mongodb",
				cmd: "del",
				data: {
					collectionName: "userGroup",
					query: query
				}
			}
			if (this.socket && query._id) {
				console.log('request: ', request);
				await this.socket.emit('frontendDataRequest', request, (err, response) => {
					if (err) {
						throw err;
					} else {
						console.log(`[SOCKETIO] delUserGroup:`, response);
						callback(null, response);
					}
				});
			}
		} catch (err) {
			console.error(`[SOCKETIO] delUserGroup`, err);
			callback(err);
		}
	}
	// (2023) get modules mongodb
	async getModules(callback) {
		try {
			const request = {
				type: "mongodb",
				cmd: "get",
				data: {
					collectionName: "modules",
					query: {}
				}
			}
			await this.getServerData('frontendDataRequest', request, (err, response) => {
				if (err) {
					throw err;
				} else {
					console.log(`[SOCKETIO] getModules:`, response);
					callback(null, response);
				}
			});
		} catch (err) {
			console.error(`[SOCKETIO] getModules`, err);
			callback(err);
		}
	}
	// (2023) set modules mongodb
	async setModules(data, callback) {
		try {
			let query = {};
			let source = null;
			let metadata = null;
			let bindings = null;
			let permissions = null;
			if (data._id) {
				query._id = data._id
			}
			if (data.source) source = { ...data.source };
			if (data.metadata) metadata = { ...data.metadata };
			if (data.bindings) bindings = { ...data.bindings };
			if (data.permissions) permissions = { ...data.permissions };
			
			const request = {
				type: "mongodb",
				cmd: "set",
				data: {
					collectionName: "modules",
					query: query,
					payload: {
						moduleName: data.moduleName,
						source: source,
						metadata: metadata,
						bindings: bindings,
						permissions: permissions
					}
				}
			}
			if (this.socket && data) {
				console.log('request: ', request);
				await this.socket.emit('frontendDataRequest', request, (err, response) => {
					if (err) {
						throw err;
					} else {
						console.log(`[SOCKETIO] setModules:`, response);
						callback(null, response);
					}
				});
			}
		} catch (err) {
			console.error(`[SOCKETIO] setModules`, err);
			callback(err);
		}
	}
	// (2023) delete modules mongodb
	async delModules(data, callback) {
		try {
			let query = {};
			if (data._id) {
				query._id = data._id
			}

			const request = {
				type: "mongodb",
				cmd: "del",
				data: {
					collectionName: "modules",
					query: query
				}
			}
			if (this.socket && query._id) {
				console.log('request: ', request);
				await this.socket.emit('frontendDataRequest', request, (err, response) => {
					if (err) {
						throw err;
					} else {
						console.log(`[SOCKETIO] delModules:`, response);
						callback(null, response);
					}
				});
			}
		} catch (err) {
			console.error(`[SOCKETIO] delModules`, err);
			callback(err);
		}
	}
	// (2023) get module scheme mongodb
	async getModuleScheme(callback) {
		try {
			const request = {
				type: "mongodb",
				cmd: "get",
				data: {
					collectionName: "moduleScheme",
					query: {}
				}
			}
			await this.getServerData('frontendDataRequest', request, (err, response) => {
				if (err) {
					throw err;
				} else {
					console.log(`[SOCKETIO] getModuleScheme:`, response);
					callback(null, response);
				}
			});
		} catch (err) {
			console.error(`[SOCKETIO] getModuleScheme`, err);
			callback(err);
		}
	}
	// (2023) set modules scheme mongodb
	async setModuleScheme(data, callback) {
		try {
			let query = {};
			let key = '';
			let children = [];
			if (data._id) {
				query._id = data._id
			}
			if (data.children) children = [ ...data.children ];
			if (data.key) key = data.key;
			
			const request = {
				type: "mongodb",
				cmd: "set",
				data: {
					collectionName: "moduleScheme",
					query: query,
					payload: {
						key: key,
						scheme: data.scheme,
						label: data.label,
						children: children
					}
				}
			}
			if (this.socket && data) {
				console.log('request: ', request);
				await this.socket.emit('frontendDataRequest', request, (err, response) => {
					if (err) {
						throw err;
					} else {
						console.log(`[SOCKETIO] setModuleScheme:`, response);
						callback(null, response);
					}
				});
			}
		} catch (err) {
			console.error(`[SOCKETIO] setModuleScheme`, err);
			callback(err);
		}
	}
	// (2023) set modules scheme mongodb
	async setModuleSchemeFromTree(data, callback) {
		try {
			let query = {};
			const children = [];
			const key = '';

			for(let i=1; i<data.length; i++) {
				children.push(data[i]);
			}
			
			const request = {
				type: "mongodb",
				cmd: "set",
				data: {
					collectionName: "moduleScheme",
					query: query,
					payload: {
						key: key,
						scheme: data[0].scheme,
						label: data[0].label,
						children: children
					}
				}
			}
			if (this.socket && data) {
				console.log('request: ', request);
				await this.socket.emit('frontendDataRequest', request, (err, response) => {
					if (err) {
						throw err;
					} else {
						console.log(`[SOCKETIO] setModuleScheme:`, response);
						callback(null, response);
					}
				});
			}
		} catch (err) {
			console.error(`[SOCKETIO] setModuleScheme`, err);
			callback(err);
		}
	}
	// (2023) delete module scheme mongodb
	async delModuleScheme(data, callback) {
		try {
			let query = {};
			if (data._id) {
				query._id = data._id
			}

			const request = {
				type: "mongodb",
				cmd: "del",
				data: {
					collectionName: "moduleScheme",
					query: query
				}
			}
			if (this.socket && query._id) {
				console.log('request: ', request);
				await this.socket.emit('frontendDataRequest', request, (err, response) => {
					if (err) {
						throw err;
					} else {
						console.log(`[SOCKETIO] delModuleScheme:`, response);
						callback(null, response);
					}
				});
			}
		} catch (err) {
			console.error(`[SOCKETIO] delModuleScheme`, err);
			callback(err);
		}
	}
	// (2023) get module bindings mongodb
	async getModuleBindings(callback) {
		try {
			const request = {
				type: "mongodb",
				cmd: "get",
				data: {
					collectionName: "moduleBindings",
					query: {}
				}
			}
			await this.getServerData('frontendDataRequest', request, (err, response) => {
				if (err) {
					throw err;
				} else {
					console.log(`[SOCKETIO] getModuleBindings:`, response);
					callback(null, response);
				}
			});
		} catch (err) {
			console.error(`[SOCKETIO] getModuleBindings`, err);
			callback(err);
		}
	}
	// (2023) get module types mongodb
	async getModuleTypes(callback) {
		try {
			const request = {
				type: "mongodb",
				cmd: "get",
				data: {
					collectionName: "moduleTypes",
					query: {}
				}
			}
			await this.getServerData('frontendDataRequest', request, (err, response) => {
				if (err) {
					throw err;
				} else {
					console.log(`[SOCKETIO] getModuleTypes:`, response);
					callback(null, response);
				}
			});
		} catch (err) {
			console.error(`[SOCKETIO] getModuleTypes`, err);
			callback(err);
		}
	}
	// (2023) get opcua browse
	async getOpcuaBrowse(server, callback) {
		try {
			let query = {
				type: 'opcServerTree'
			};
			if (server) {
				query.id = server;
			}
			
			const request = {
				type: "mongodb",
				cmd: "get",
				data: {
					collectionName: "configOpc",
					query: query
				}
			}
			await this.getServerData('frontendDataRequest', request, (err, response) => {
				if (err) {
					throw err;
				} else {
					console.log(`[SOCKETIO] getOpcuaBrowse:`, response);
					callback(null, response);
				}
			});
		} catch (err) {
			console.error(`[SOCKETIO] getOpcuaBrowse`, err);
			callback(err);
		}
	}
	// (2023) get opcua value
	async getOpcuaValue(server, identifier, callback) {
		try {
			const request = {
				type: "opcua",
				cmd: "get",
				data: {
					funct: "value",
					server: server,
					identifier: identifier
				}
			}
			await this.getServerData('frontendDataRequest', request, (err, response) => {
				if (err) {
					throw err;
				} else {
					console.log(`[SOCKETIO] getOpcuaValue:`, response);
					callback(null, response);
				}
			});
		} catch (err) {
			console.error(`[SOCKETIO] getOpcuaValue`, err);
			callback(err);
		}
	}
	// (2023) get redis hash
	async getRedisHash(hash, callback) {
		try {
			const request = {
				type: "redis",
				cmd: "get",
				data: {
					funct: "hash",
					hash: hash
				}
			}
			await this.getServerData('frontendDataRequest', request, (err, response) => {
				if (err) {
					throw err;
				} else {
					console.log(`[SOCKETIO] getRedisHash:`, response);
					callback(null, response);
				}
			});
		} catch (err) {
			console.error(`[SOCKETIO] getRedisHash`, err);
			callback(err);
		}
	}
	// (2023) get redis nodes
	async getRedisNodes(hash, callback) {
		try {
			const request = {
				type: "redis",
				cmd: "get",
				data: {
					funct: "nodes",
					hash: hash
				}
			}
			await this.getServerData('frontendDataRequest', request, (err, response) => {
				if (err) {
					throw err;
				} else {
					console.log(`[SOCKETIO] getRedisNodes:`, response);
					callback(null, response);
				}
			});
		} catch (err) {
			console.error(`[SOCKETIO] getRedisNodes`, err);
			callback(err);
		}
	}
	// (2023) get redis nodes and parameter in array
	async getRedisNodesParam(hash, callback) {
		try {
			const request = {
				type: "redis",
				cmd: "get",
				data: {
					funct: "nodesParam",
					hash: hash
				}
			}
			await this.getServerData('frontendDataRequest', request, (err, response) => {
				if (err) {
					throw err;
				} else {
					console.log(`[SOCKETIO] getRedisNodesParam:`, response);
					callback(null, response);
				}
			});
		} catch (err) {
			console.error(`[SOCKETIO] getRedisNodesParam`, err);
			callback(err);
		}
	}
	// (2023) get redis nodes and parameter in array
	async getRedisNodesParamScaled(hash, callback) {
		try {
			const request = {
				type: "redis",
				cmd: "get",
				data: {
					funct: "nodesParam",
					hash: hash
				}
			}
			await this.getServerData('frontendDataRequest', request, (err, response) => {
				if (err) {
					throw err;
				} else {
					console.log(`[SOCKETIO] getRedisNodesParamScaled:`, response);
					const result = [];
					if (response) {
						response.forEach((entry) => {
								const newObj = parseNode(entry);
								result.push(newObj);
						});
						callback(null, result);
					} else {
						callback(null, null);
					}
				}
			});
		} catch (err) {
			console.error(`[SOCKETIO] getRedisNodesParamScaled`, err);
			callback(err);
		}
	}
	// (2023) set/write opc value(s)
	async setOpcValue(data, callback) {
		try {
			if (this.socket && data) {
				console.log('[SOCKETIO] request: ', data);
				await this.socket.emit('opcuaWriteValue', data, (err, response) => {
					if (err) {
						throw err;
					} else {
						console.log(`[SOCKETIO] setOpcValue:`, response);
						callback(null, response);
					}
				});
			}
		} catch (err) {
			console.error(`[SOCKETIO] setOpcValue`, err);
			callback(err);
		}
	}
	// (2024) run server command
	async runServerCmd(cmd, callback) {
		try {
			if (this.socket && cmd) {
				let data = {};
				data.cmd = cmd;
				console.log('[SOCKETIO] request: ', data);
				await this.socket.emit('runSystemCmd', data, (err, response) => {
					if (err) {
						throw err;
					} else {
						console.log(`[SOCKETIO] runSystemCmd:`, response);
						callback(null, response);
					}
				});
			}
		} catch (err) {
			console.error(`[SOCKETIO] runSystemCmd`, err);
			callback(err);
		}
	}
	// (2023) get modules mongodb
	async getChart2(data, callback) {
		// const pipeline = [
		// 	{
		// 		$match: {
		// 			$and: [
		// 				// { "timestamp": { $gte: new Date(data.startTime) } },
		// 				// { "timestamp": { $lte: new Date(data.endTime) } },
		// 				{ "timestamp": { $gte: new Date(Date.now() - (23 * 60 * 60 * 1000)) } },
		// 				{ "timestamp": { $lte: new Date(Date.now()) } },
		// 				{ "hash": data.hash }
		// 			]
		// 		}
		// 	},
		// 	{
		// 		$group: {
		// 			_id: {
		// 				// $dateToString: { format: "%Y-%m-%dT%H", date: "$timestamp" }
		// 				$dateToString: { date: "$timestamp", format: "%d.%m.%Y - %H:00h", timezone: "Europe/Vienna" }
		// 			},
		// 			value: { $first: "$value" },
		// 		}
		// 	},
		// 	{
		// 		$sort: {
		// 			"_id": 1
		// 		}
		// 	}
		// 	]

		try {
			if (!data.cumulative) data.cumulative = false;
			const request = {
				type: "mongodb",
				cmd: "getChart",
				data: {
					collectionName: "record",
					node: {
						hash: data.hash,
						startTime: data.startTime,
						endTime: data.endTime,
						format: data.format,
						cumulative: data.cumulative
					}
				}
			}
			console.log(request);
			await this.getServerData('frontendDataRequest', request, (err, response) => {
				if (err) {
					throw err;
				} else {
					console.log(`[SOCKETIO] getChart:`, response);
					callback(null, response);
				}
			});
		} catch (err) {
			console.error(`[SOCKETIO] getChart`, err);
			callback(err);
		}
	}

	// (2023) get modules mongodb
	async getChart(data, callback) {
		try {
			if (!data.cumulative) data.cumulative = false;
			const request = {
				type: "influxdb",
				cmd: "getChart",
				data: {
					collectionName: "record",
					node: {
						hash: data.hash,
						startTime: data.startTime,
						endTime: data.endTime,
						format: data.format,
						window: data.window,
						cumulative: data.cumulative
					}
				}
			}
			console.log(request);
			await this.getServerData('frontendDataRequest', request, (err, response) => {
				if (err) {
					throw err;
				} else {
					console.log(`[SOCKETIO] getChart:`, response);
					callback(null, response);
				}
			});
		} catch (err) {
			console.error(`[SOCKETIO] getChart`, err);
			callback(err);
		}
	}

	async removeUser(_id) {
		if (this.socket) {
			await this.socket.emit('remove_user', (_id));
		}
	}
	async removeDatabaseDocument(section, data, callback) {
		if (this.socket) {
			await this.socket.emit('removeDatabaseDocument', section, data, (response) => {
				console.log(response);
				callback(response);
			});
		}
	}
	async setConfigIO(data) {
		if (this.socket) {
			await this.socket.emit('set_status_io', data);
		}
	}
	async setConfigIOType(data, type) {
		if (this.socket) {
			if (type === "types_category") await this.socket.emit('set_config_io_types_category', data);
			if (type === "types_department") await this.socket.emit('set_config_io_types_department', data);
			if (type === "types_io") await this.socket.emit('set_config_io_types_io', data);
			if (type === "types_unit") await this.socket.emit('set_config_io_types_unit', data);
			if (type === "types_server") await this.socket.emit('set_config_io_types_server', data);
			if (type === "types_parameters") await this.socket.emit('set_config_io_types_parameters', data);
			if (type === "types_icons") await this.socket.emit('set_config_io_types_icons', data);
		}
	}
	async getOpcBrowse(callback) {
		if (this.socket) {
			await this.socket.emit('opc', ('browse'));
		}
		this.socket.on('opc_browse', (data) => {
			// console.log(data);
			// _system_log = data;
			callback(data);
		});
	}
	async getOPCAppTree(callback) {
		if (this.socket) {
			await this.socket.emit('opc', ('appTreeView'));
		}
		this.socket.on('opc_app_treeview', (data) => {
			// console.log(data);
			// _system_log = data;
			if (data !== null) {
				callback(data);
			}
		});
	}
	async sendMail(data) {
		if (this.socket) {
			await this.socket.emit('sendMail', data);
		}
	}
	async sendPush(data) {
		if (this.socket) {
			await this.socket.emit('sendPush', data);
		}
	}
}

export default new SocketioService()